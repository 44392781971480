import {
  AppBar,
  Bubble,
  Error,
  getColumnVisibility,
  getExternalIdLabel,
  getSortOrder,
  setColumnVisibility,
  useFilters,
} from '@/common';
import {
  getCurrentFilters,
  getInitialFilterModel,
  getInitialSortModel,
  getOnFilterModelChange,
  getOnPageChange,
  getOnPageSizeChange,
  getOnSortModelChange,
  refreshData,
} from '@/common/GridLoading';

import { OparetaTable } from '@/common/OparetaTable';
import { paginationValue } from '@/common/filter';
import { Grid, Modal, Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { floatCashListFetch } from './floatCashListSlice';
import {
  getCreatedAt,
  getFloatCashAgentNumber,
  getFloatCashAmount,
  getFloatCashBankName,
  getFloatCashCurrency,
  getFloatCashToAccount,
  getFloatTypes,
  getStatusColor,
  getRequestStatuses,
  getStatus,
  getStatusBackground,
} from './util';
import { FEATURES, hasFeature } from '@/common/features';
import { useNavigate } from 'react-router-dom';

export const DisbursementList = ({ embedded }) => {
  // console.log('RXD:DisbursementList');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { floatCashList, isLoading, error, lastFilter } = useSelector(
    (state) => {
      return state.floatCashList;
    }
  );

  const w = (callback) => (param) => callback(param.row);

  const [filters, setFilters] = useFilters();
  const [transactionType, setTransactionType] = useState('REMITTANCE');
  const [serverPage, serverPerPage] = paginationValue(filters, 1, 15);
  const [fullScreenUrl, setFullScreenUrl] = useState('');

  const handleClose = () => setFullScreenUrl('');

  useEffect(() => {
    const currentFilters = getCurrentFilters(filters, embedded, ['fa.uuid']);
    if (!currentFilters.has('oo')) {
      currentFilters.set('oo', '-createdAt');
    }
    currentFilters.set('transactionType', `isAnyOf:${transactionType}`);

    if (refreshData(isLoading, error, lastFilter, currentFilters)) {
      dispatch(floatCashListFetch(currentFilters));
      setFilters(currentFilters);
    }
  }, [
    dispatch,
    filters,
    isLoading,
    lastFilter,
    setFilters,
    transactionType,
    error,
    embedded,
  ]);

  const columns = [
    {
      field: 'createdAt',
      headerName: 'Disbursed At',
      type: 'dateTime',
      valueGetter: w(getCreatedAt),
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
    {
      field: 't.agentName',
      headerName: 'Agent Name',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashToAccount),
    },
    {
      field: 'phoneNumber',
      headerName: 'Agent Number',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      valueGetter: w(getFloatCashAgentNumber),
    },
    ...(hasFeature(FEATURES.AGENT_EXTERNAL_ID)
      ? [
          {
            field: 't.agentExternalId',
            headerName: getExternalIdLabel(),
            valueGetter: w(
              (transaction) => transaction?.agentExternalId ?? '-'
            ),
            minWidth: 200,
          },
        ]
      : []),
    {
      field: 'currency',
      headerName: 'Currency',
      minWidth: 200,
      valueGetter: w(getFloatCashCurrency),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'floatCashRequest.status',
      headerName: 'Request Status',
      filterable: false,
      minWidth: 200,
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      valueOptions: getRequestStatuses().map((status) => ({
        value: status,
        label: getStatus(status),
      })),
      renderCell: w((t) => (
        <Bubble
          label={getStatus(t?.floatCashRequest?.status)}
          color={getStatusColor(t?.floatCashRequest?.status)}
          background={getStatusBackground(t?.floatCashRequest?.status)}
        />
      )),
    },
    {
      field: 'status',
      headerName: 'Transaction Status',
      minWidth: 200,
      sortingOrder: getSortOrder(),
      type: 'singleSelect',
      filterable: false,
      valueOptions: getRequestStatuses().map((status) => ({
        value: status,
        label: getStatus(status),
      })),
      renderCell: w((t) => (
        <Bubble
          label={getStatus(t?.status)}
          color={getStatusColor(t?.status)}
          background={getStatusBackground(t?.status)}
        />
      )),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 200,
      valueGetter: w(getFloatCashAmount),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'fa.name',
      headerName: 'Disbursed By',
      minWidth: 200,
      valueGetter: w((t) => t?.user?.fieldAgent?.name),
      sortingOrder: getSortOrder(),
    },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      minWidth: 200,
      sortingOrder: getSortOrder(),
    },
  ];

  return (
    <>
      {!embedded && <AppBar>Disbursements</AppBar>}

      {error && (
        <Error
          error={error}
          onRetry={() => dispatch(floatCashListFetch(filters))}
        />
      )}

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={transactionType}
          onChange={(e, value) => setTransactionType(value)}
          aria-label="basic tabs example"
        >
          <Tab label="Remittances" value="REMITTANCE" />
          <Tab label="Float Requests" value="FLOAT_OUT" />
        </Tabs>
      </Box>

      <Modal
        open={Boolean(fullScreenUrl)}
        onClose={handleClose}
        onClick={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          sx={{
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            component="img"
            sx={{ maxWidth: '100%', maxHeight: '100%' }}
            src={fullScreenUrl}
            alt={fullScreenUrl}
          />
        </Grid>
      </Modal>

      <div
        style={{
          height: 'calc(100vh - 190px)',
          minHeight: 300,
        }}
      >
        <OparetaTable
          getRowId={(transaction) => transaction.uuid}
          rows={floatCashList ?? []}
          columns={columns}
          onCellClick={(param, e) => {
            const request = param.row;
            if (e.ctrlKey) {
              window.open(`/float-cash/disbursement/${request.uuid}`);
            } else {
              navigate(`/float-cash/disbursement/${request.uuid}`);
            }
          }}
          onColumnVisibilityModelChange={(visible) =>
            setColumnVisibility('DisbursementList', visible)
          }
          loading={isLoading}
          page={serverPage - 1}
          onFilterModelChange={getOnFilterModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onSortModelChange={getOnSortModelChange(
            filters,
            setFilters,
            serverPerPage
          )}
          onPageSizeChange={getOnPageSizeChange(filters, setFilters)}
          onPageChange={getOnPageChange(filters, setFilters, serverPerPage)}
          initialState={{
            sorting: getInitialSortModel(filters, columns),
            filter: getInitialFilterModel(filters, columns),
            columns: {
              columnVisibilityModel: getColumnVisibility('DisbursementList', {
                currency: false,
              }),
            },
          }}
        />
      </div>
    </>
  );
};
